import { motion } from 'framer-motion';
import { useInView } from "react-intersection-observer";

export default function Contacts(props) {
    const [ref, inView] = useInView({
        triggerOnce: true, // Запустить только один раз при первом появлении на экране
    });

    const revealForm = {
        hidden: {
            scale: 0.5,
            opacity: 0,

        },
        visible: {
            scale: 1,
            opacity: 1,
            transition: {duration: 0.4, delay: 0.5, ease: "easeInOut"},
        },
    };

    const revealText = {
        hidden: {
            x: -200,
            opacity: 0,

        },
        visible:{
            x: 0,
            opacity: 1,
            transition: {duration: 0.4, delay: 0, ease: "easeInOut"},
        },
    };

    return(
        <section className="contacts-page" id='contacts-page'>
            <div className="request">
                <motion.p 
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={revealText}
                >
                    Давайте обсудим ваш проект!
                    <br />
                    <br />
                    Для того чтобы принять ваш заказ, мне нужно поговорить с вами обо всех деталях и нюансах.
                </motion.p>
                <motion.form  
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={revealForm}
                    action="https://formspree.io/f/xeqyewkj" 
                    method="POST"
                >
                    <input 
                        type="text" 
                        name="name" 
                        id="name" 
                        placeholder="Имя" 
                        required
                    />
                    <input 
                        type="email" 
                        name="email" 
                        id="email" 
                        placeholder="Эл. почта"  
                        required
                    />
                    <textarea 
                        name="message" 
                        id="message" 
                        cols="30" 
                        rows="10" 
                        placeholder="Сообщение"
                        required 
                    >
                    </textarea>
                    <button type='submit'>Отправить</button>
                </motion.form>
            </div>
            <div className="social-links">
                <a href="https://t.me/RailTH" target='_blank' rel="noopener noreferrer">
                    <motion.svg width="93" height="93" viewBox="0 0 93 93" fill="none" xmlns="http://www.w3.org/2000/svg" whileTap={{scale: 0.9}}>
                        <path d="M11.2956 45.7561C35.6461 35.0437 67.1576 21.983 71.5073 20.1753C82.9173 15.4439 86.4183 16.3507 84.6726 26.8287C83.4191 34.3597 79.8018 59.2934 76.9188 74.8128C75.208 84.0159 71.3698 85.1067 65.3345 81.1252C62.4321 79.209 47.7826 69.5215 44.6032 67.2469C41.7008 65.1737 37.698 62.6802 42.718 57.7686C44.5044 56.0191 56.2146 44.8377 65.3383 36.1345C66.5338 34.9914 65.0322 33.1139 63.6527 34.0304C51.3554 42.1853 34.3054 53.5042 32.1354 54.9786C28.8571 57.2048 25.7087 58.2259 20.057 56.6022C15.7868 55.3758 11.6153 53.913 9.99171 53.355C3.7394 51.2082 5.22352 48.4279 11.2956 45.7561Z" fill="white"/>
                    </motion.svg>
                </a>
                <a href="https://discordapp.com/users/587109715411075082/" target='_blank' rel="noopener noreferrer">
                    <motion.svg width="94" height="93" viewBox="0 0 94 93" fill="none" xmlns="http://www.w3.org/2000/svg" whileTap={{scale: 0.9}}>
                        <path d="M78.7247 19.7664C78.4089 19.3479 77.9865 19.0224 77.5002 18.8248L76.1129 18.2591C70.8274 16.1026 67.0106 14.5449 62.4691 13.6943C59.7391 13.1828 56.7941 14.7386 55.2868 17.4783C52.8009 17.2477 50.1717 17.1702 47.2481 17.2458C44.212 17.1741 41.5596 17.2458 39.0699 17.4783C37.5857 14.7832 34.6504 13.2661 31.9166 13.7738C27.0244 14.6883 22.9964 16.3274 16.8564 18.8229C16.3701 19.0205 15.9478 19.346 15.6319 19.7645C5.31863 33.4336 1.20919 48.2671 3.06919 65.1098C3.16994 66.0107 3.68338 66.8128 4.46031 67.2817C11.3423 71.4221 17.304 74.2625 23.225 76.2174C26.0809 77.161 29.2526 76.0857 30.9498 73.6076L33.5964 69.7346C37.9481 70.8331 42.4837 71.4318 47.1802 71.5209C51.7586 71.4338 56.2749 70.8312 60.6304 69.7268L63.4204 73.6929C64.7204 75.5432 66.7839 76.5778 68.9442 76.5778C69.6572 76.5778 70.3799 76.4654 71.089 76.231C77.0216 74.2761 82.9969 71.4318 89.8963 67.2817C90.6713 66.8148 91.1867 66.0107 91.2874 65.1098C93.1455 48.2651 89.0361 33.4316 78.7247 19.7664ZM32.6122 54.2171C28.8806 54.2171 25.8096 50.2859 25.8096 45.5081C25.8096 40.7302 28.8806 36.799 32.6122 36.799C36.3438 36.799 39.4147 40.7302 39.4147 45.5081C39.4147 50.2859 36.3438 54.2171 32.6122 54.2171ZM59.7372 54.2694C56.0404 54.2694 52.9986 50.315 52.9986 45.5081C52.9986 40.7011 56.0404 36.7467 59.7372 36.7467C63.4339 36.7467 66.4758 40.7011 66.4758 45.5081C66.4758 50.315 63.4339 54.2694 59.7372 54.2694Z" fill="white"/>
                    </motion.svg>
                </a>
                <a href="https://github.com/Rail-TH" target='_blank' rel="noopener noreferrer">
                    <motion.svg width="94" height="93" viewBox="0 0 94 93" fill="none" xmlns="http://www.w3.org/2000/svg" whileTap={{scale: 0.9}}>
                        <path d="M85.5834 46.5C85.5834 63.86 74.1909 78.5463 58.4584 83.4675V73.625C58.4584 70.3119 57.0634 67.3475 54.8546 65.2163C65.0071 63.3756 70.0834 57.4663 70.0834 46.5C70.0834 41.7531 69.1146 37.9944 67.2159 35.0688C68.0684 31.7556 68.5721 27.0475 66.2084 23.25C61.6359 23.25 58.439 25.9431 56.5596 28.1519C53.7502 27.4738 50.5146 27.125 46.8334 27.125C43.3459 27.125 40.1296 27.5125 37.2621 28.3069C35.4021 26.0788 32.1471 23.25 27.4584 23.25C24.7071 27.6675 25.8309 32.4338 26.8771 35.1075C24.7846 38.0331 23.5834 41.7919 23.5834 46.5C23.5834 57.4663 28.6596 63.3756 38.8121 65.2163C37.514 66.4756 36.5065 68.0063 35.8865 69.75H31.3334C28.5434 69.75 27.4584 68.51 25.9665 66.495C24.4746 64.48 22.8665 63.1238 20.9484 62.5619C19.9215 62.4456 19.224 63.2788 20.1346 64.015C23.1765 66.2044 23.3896 69.7888 24.6102 72.1331C25.7146 74.245 28.0009 75.5625 30.5777 75.5625H35.2084V83.4675C19.4759 78.5463 8.08337 63.86 8.08337 46.5C8.08337 25.0906 25.424 7.75 46.8334 7.75C68.2427 7.75 85.5834 25.0906 85.5834 46.5Z" fill="white"/>
                    </motion.svg>
                </a>
                <a href="https://vk.com/railya2021" target='_blank' rel="noopener noreferrer">
                    <motion.svg width="93" height="93" viewBox="0 0 93 93" fill="none" xmlns="http://www.w3.org/2000/svg" whileTap={{scale: 0.9}}>
                        <path d="M88.6658 68.204C85.1841 61.9381 76.2154 54.409 72.5129 51.1327C71.4996 50.2356 71.3911 48.6837 72.3133 47.6917C79.3794 40.0986 85.0311 31.374 86.9763 26.1021C87.8346 23.7732 86.0579 21.3126 83.5547 21.3126H76.2833C73.8866 21.3126 72.4838 22.1729 71.8154 23.5427C65.9428 35.5765 60.9053 40.7845 57.3791 43.8283C55.4047 45.5333 52.3261 44.1209 52.3261 41.5265C52.3261 36.5278 52.3261 30.0662 52.3261 25.484C52.3261 23.2617 50.5087 21.4637 48.267 21.4637L34.9835 21.3126C33.3114 21.3126 32.3562 23.2055 33.3599 24.5327L35.5531 27.6812C36.3785 28.772 36.8241 30.0992 36.8241 31.4612L36.8125 45.6399C36.8125 48.1063 33.8229 49.3114 32.0482 47.5832C26.0439 41.7358 20.8339 29.795 18.9584 24.0852C18.4159 22.4325 16.8679 21.3165 15.1125 21.3126L7.94956 21.2952C5.26225 21.2952 3.29762 23.843 4.02806 26.4082C10.5729 49.3812 24.0017 71.2362 47.9337 73.6057C50.2917 73.8382 52.3261 71.9395 52.3261 69.5893V62.1377C52.3261 60.0006 54.002 58.1639 56.1565 58.1096C56.2321 58.1077 56.3076 58.1077 56.3832 58.1077C62.713 58.1077 69.8023 67.3205 72.2823 71.6295C72.9953 72.8695 74.3283 73.6251 75.7698 73.6251H85.405C88.2163 73.6251 90.0201 70.6433 88.6658 68.204Z" fill="white"/>
                    </motion.svg>
                </a>
            </div>
            <footer>
                Roman Konstantinov ©2025
            </footer>
        </section>
    )
};